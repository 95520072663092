import { PDF_REPORTS_EXT_POINT, } from '@ui-platform/ui-core-services-extension-points';
export default {
    point: PDF_REPORTS_EXT_POINT,
    configs: [
        {
            id: 'sase.dem_app_detail',
            config: {
                name: 'Activity Insights - Application Details',
                report_type: 'sase.dem_app_detail',
                category: 'security',
                version: 2,
                isAllowed: false,
            },
        },
        {
            id: 'sase.dem_user_detail',
            config: {
                name: 'Activity Insights - User Details',
                report_type: 'sase.dem_user_detail',
                category: 'security',
                version: 2,
                isAllowed: false,
            },
        },
    ],
};
