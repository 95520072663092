import { getAuthState, getFrameworkVars } from '@sparky/framework';
import { AdemInsightsAppIdList } from '../../computeRolePermission';
export function getFrameworkInstance() {
    var _a, _b;
    var authData = getAuthState();
    var isAdemInsightsAppRunning = AdemInsightsAppIdList.some(function (app) { var _a; return (_a = authData.instances) === null || _a === void 0 ? void 0 : _a.isRunningStatus(app); });
    if (getFrameworkVars().aiops_free &&
        ((_a = authData.instances) === null || _a === void 0 ? void 0 : _a.isRunningStatus('strata_insights_free'))) {
        // aiops free fqdn
        return 'free';
    }
    else if (!getFrameworkVars().aiops_free &&
        ((_b = authData.instances) === null || _b === void 0 ? void 0 : _b.isRunningStatus('strata_insights_free')) &&
        !isAdemInsightsAppRunning) {
        // scenario where a tenant with aiops free + other instances loads in SCM instead of aiops free domain
        // in this scenario the tenant has aiops free instance but getFrameworkVars().aiops_free is false
        // HybridFramework
        return 'free';
    }
    else {
        // fallback
        return getFrameworkVars().aiops_free ? 'free' : 'premium';
    }
}
