import { IAMRolePermissions } from './helpers/rolePermissionsHelpers';
export var AdemInsightsAppIdList = [
    'ng_casb',
    'logging_service',
    'strata_insights_ngfw',
    'strata_insights_free',
    'strata_insights',
    'prisma_access',
    'prisma_access_panorama',
    'all',
    'strata_cloud_manager',
];
var AdemAppIdList = [
    'prisma_access',
    'prisma_access_panorama',
    'all',
    'strata_cloud_manager',
];
export var computeRolePermissionsAdem = function (authState) {
    var permissions = { read: false, write: false };
    var roles = [];
    var access = authState.access;
    //Set the role based on tsgAuth
    if (authState.tsg_id && access) {
        access.forEach(function (accessEntries) {
            if (typeof accessEntries === 'object') {
                var app = accessEntries.app_id;
                if (app === '' || AdemAppIdList.includes(app)) {
                    console.info("Found app access: ".concat(app));
                    var roleList = Array.isArray(accessEntries.aggRoleNames)
                        ? accessEntries.aggRoleNames
                        : [];
                    roles.push.apply(roles, roleList);
                }
            }
        });
        roles.forEach(function (role) {
            var iamRole = IAMRolePermissions(role);
            if (iamRole.read === true)
                permissions.read = iamRole.read;
            if (iamRole.write === true)
                permissions.write = iamRole.write;
        });
    }
    return { permissions: permissions, roles: roles };
};
export var computeRolePermissionsInsights = function (authState) {
    var roles = [];
    var access = authState.access;
    //Set the role based on tsgAuth
    if (authState.tsg_id && access) {
        access.forEach(function (accessEntries) {
            if (typeof accessEntries === 'object') {
                var app = accessEntries.app_id;
                if (app === '' || AdemInsightsAppIdList.includes(app)) {
                    console.info("Found app access: ".concat(app));
                    var roleList = Array.isArray(accessEntries.aggRoleNames)
                        ? accessEntries.aggRoleNames
                        : [];
                    roles.push.apply(roles, roleList);
                }
            }
        });
    }
    return { roles: roles };
};
export var checkAdemMicroAppStatus = function (authState) {
    var access = authState.access;
    if (authState.tsg_id && access) {
        return access.some(function (accessEntries) {
            if (typeof accessEntries === 'object') {
                var app = accessEntries.app_id;
                if (app === '' || AdemInsightsAppIdList.includes(app)) {
                    return true;
                }
            }
        });
    }
    return false;
};
