import { Loader } from '@googlemaps/js-api-loader';
var loader;
export var setGoogleMapApiKey = function (apiKey) {
    if (!apiKey)
        return;
    loader = new Loader({
        apiKey: apiKey,
        version: '3.exp',
        libraries: ['geometry', 'drawing', 'places'],
    });
};
export function loadGoogleMap() {
    return loader ? loader.load() : Promise.resolve();
}
