export var Role;
(function (Role) {
    Role["SecurityAdmin"] = "securityadmin";
    Role["CryptoAdmin"] = "cryptoadmin";
    Role["AuditAdmin"] = "auditadmin";
    Role["SuperReader"] = "superreader";
    Role["AppAdministrator"] = "App Admin";
    Role["InstanceAdministrator"] = "Instance Admin";
    Role["AccountAdministrator"] = "Account Super User";
})(Role || (Role = {}));
export var IAMRole;
(function (IAMRole) {
    IAMRole["Superuser"] = "superuser";
    IAMRole["ViewOnlyAdmin"] = "view_only_admin";
    IAMRole["NetworkAdmin"] = "network_admin";
    IAMRole["SecurityAdmin"] = "security_admin";
    IAMRole["SocAnalyst"] = "soc_analyst";
    IAMRole["Auditor"] = "auditor";
    IAMRole["AdemTier1Support"] = "adem_tier_1_support";
    IAMRole["Tier1Support"] = "tier_1_support";
    IAMRole["Tier2Support"] = "tier_2_support";
    IAMRole["IAMAdmin"] = "iam_admin";
    IAMRole["DataSecurityAdmin"] = "data_security_admin";
    IAMRole["DeploymentAdmin"] = "deployment_admin";
    IAMRole["BusinessAdmin"] = "business_admin";
    IAMRole["MSPSuperuser"] = "msp_superuser";
    IAMRole["MSPIAMAdmin"] = "msp_iam_admin";
})(IAMRole || (IAMRole = {}));
export function rolePermissions(role) {
    if (role === Role.SecurityAdmin) {
        return {
            read: true,
            write: false,
        };
    }
    else if (role === Role.CryptoAdmin) {
        return {
            read: true,
            write: false,
        };
    }
    else if (role === Role.AuditAdmin) {
        return {
            read: true,
            write: false,
        };
    }
    else if (role === Role.SuperReader) {
        return {
            read: true,
            write: false,
        };
    }
    else if (role === Role.AppAdministrator) {
        return {
            read: true,
            write: true,
        };
    }
    else if (role === Role.InstanceAdministrator) {
        return {
            read: true,
            write: true,
        };
    }
    else if (role === Role.AccountAdministrator) {
        return {
            read: true,
            write: true,
        };
    }
    return {
        read: false,
        write: false,
    };
}
export function IAMRolePermissions(role) {
    if (role === IAMRole.Superuser) {
        return {
            read: true,
            write: true,
        };
    }
    else if (role === IAMRole.ViewOnlyAdmin) {
        return {
            read: true,
            write: false,
        };
    }
    else if (role === IAMRole.NetworkAdmin) {
        return {
            read: true,
            write: true,
        };
    }
    else if (role === IAMRole.SecurityAdmin) {
        return {
            read: true,
            write: false,
        };
    }
    else if (role === IAMRole.SocAnalyst) {
        return {
            read: true,
            write: false,
        };
    }
    else if (role === IAMRole.Auditor) {
        return {
            read: true,
            write: false,
        };
    }
    else if (role === IAMRole.AdemTier1Support) {
        return {
            read: true,
            write: false,
        };
    }
    else if (role === IAMRole.Tier1Support) {
        return {
            read: true,
            write: false,
        };
    }
    else if (role === IAMRole.Tier2Support) {
        return {
            read: true,
            write: false,
        };
    }
    else if (role === IAMRole.IAMAdmin) {
        return {
            read: true,
            write: false,
        };
    }
    else if (role === IAMRole.DataSecurityAdmin) {
        return {
            read: true,
            write: false,
        };
    }
    else if (role === IAMRole.DeploymentAdmin) {
        return {
            read: true,
            write: false,
        };
    }
    else if (role === IAMRole.BusinessAdmin) {
        return {
            read: false,
            write: false,
        };
    }
    else if (role === IAMRole.MSPSuperuser) {
        return {
            read: true,
            write: true,
        };
    }
    else if (role === IAMRole.MSPIAMAdmin) {
        return {
            read: false,
            write: false,
        };
    }
    return {
        read: false,
        write: false,
    };
}
export var tagActionEnabledRoles = [
    IAMRole.Superuser,
    IAMRole.MSPSuperuser,
    IAMRole.SecurityAdmin,
]; //only enable changing tags for these roles
