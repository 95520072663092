import { getFrameworkInstance } from './getFrameworkInstance';
export var AiopsFreePath = '/aiops-free';
/**
 * this is to add aiops-free prefix to the routes if the framework instance is 'free'
 *
 * @param routes {} | string
 * @returns
 */
export function addRoutesPrefixForAIOPSFreeIfNeeded(routes) {
    var prefix = getFrameworkInstance() === 'free' ? AiopsFreePath : '';
    if (typeof routes === 'string') {
        return "".concat(prefix).concat(routes);
    }
    else {
        return Object.keys(routes).reduce(function (acc, route) {
            acc[route] = "".concat(prefix).concat(routes[route]);
            return acc;
        }, {});
    }
}
